@mixin tenants-theme($theme) {

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $accent: map-get($theme, accent);

    // List
    scrumboard-board-list {

        .list {

            .list-header,
            .list-content,
            .list-footer {
                background: map-get($background, app-bar);
            }

            .list-header {
                border-bottom-color: map-get($foreground, divider);
            }

            .list-footer {
                border-top-color: map-get($foreground, divider);
            }

        }
    }

    // Add list
    scrumboard-board-add-list {

        .new-list {
            background: map-get($background, app-bar);

            .new-list-form {

                > input {
                    background: map-get($background, app-bar);
                    color: map-get($foreground, text);
                }
            }
        }
    }

    // Board
    scrumboard-board {

        #board {

            > .header {
                background-color: map-get($accent, default);
                color: map-get($accent, default-contrast);
            }
        }
    }

}