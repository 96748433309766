@use '@angular/material' as mat;

//@include mat.core();
@import "@angular/material/theming";

// -----------------------------------------------------------------------------------------------------
// @ Custom color maps for Fuse
// -----------------------------------------------------------------------------------------------------
$fuse-white: (
    500: white,
    contrast: (
        500: $dark-primary-text
    )
);

$fuse-black: (
    500: black,
    contrast: (
        500: $light-primary-text,
    )
);

$u2b-main: (
    50 : #afb6c5,
    100 : #838da5,
    200 : #65708b,
    300 : #474f61,
    400 : #3a4050,
    500 : #2d323e,
    600 : #20242c,
    700 : #13151b,
    800 : #060709,
    900 : #000000,
    A100 : #93a7d7,
    A200 : #496abb,
    A400 : #374567,
    A700 : #343d50,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #ffffff,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$u2b-accent-blue: (
    50 : #ffffff,
    100 : #b6e8fe,
    200 : #7ed6fd,
    300 : #38bffd,
    400 : #19b6fc,
    500 : #03a9f4,
    600 : #0394d6,
    700 : #027fb8,
    800 : #026a99,
    900 : #02557b,
    A100 : #f7fdff,
    A200 : #91ddff,
    A400 : #2bbdff,
    A700 : #11b5ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$u2b-accent-yellow: (
    50 : #ffffff,
    100 : #ffe6bd,
    200 : #ffd085,
    300 : #ffb53d,
    400 : #ffa91f,
    500 : #ff9d00,
    600 : #e08a00,
    700 : #c27700,
    800 : #a36400,
    900 : #855200,
    A100 : #ffffff,
    A200 : #ffd899,
    A400 : #ffb133,
    A700 : #ffa71a,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$u2b-red: (
    50 : #ffffff,
    100 : #ffeeed,
    200 : #ffb9b5,
    300 : #ff756d,
    400 : #ff584f,
    500 : #ff3b30,
    600 : #ff1e11,
    700 : #f20d00,
    800 : #d30b00,
    900 : #b50a00,
    A100 : #ffffff,
    A200 : #ffccc9,
    A400 : #ff6b63,
    A700 : #ff5349,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$u2b-success: (
    50 : #ffffff,
    100 : #e4f9e7,
    200 : #b6eec0,
    300 : #7ce18d,
    400 : #63dc78,
    500 : #4ad662,
    600 : #31d04c,
    700 : #2ab942,
    800 : #24a039,
    900 : #1f8730,
    A100 : #ffffff,
    A200 : #bcfdc7,
    A400 : #67ec7e,
    A700 : #5ae071,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

// Generate Fuse color classes for custom palettes
$custom_palettes: (
    fuse-white: $fuse-white,
    fuse-black: $fuse-black,
    fuse-navy: $u2b-main,
    // naming it mat-success here, because otherwise angular material is adding an additional "mat-" and [color] directive on buttons does not work
    mat-success: $u2b-success
);

@include fuse-color-classes($custom_palettes);

// -----------------------------------------------------------------------------------------------------
// @ Typography
// -----------------------------------------------------------------------------------------------------

// Angular Material typography
$typography: mat.define-legacy-typography-config(
    $font-family: 'Verdana, Arial, sans-serif',
    $title: mat.define-typography-level(20px, 32px, 600),
    $body-2: mat.define-typography-level(14px, 24px, 600),
    $button: mat.define-typography-level(14px, 14px, 600),
    $input: mat.define-typography-level(16px, 1.2, 400/*, 'Roboto, "Helvetica Neue", sans-serif'*/), // line-height must be unitless !!!
);

// Setup the typography
@include mat.all-legacy-component-typographies($typography);

// -----------------------------------------------------------------------------------------------------
// @ Component theming
// -----------------------------------------------------------------------------------------------------

// Import app and page component themes here to enable theming for them
@import "src/app/layout/components/chat-panel/chat-panel.theme";
@import "src/app/layout/components/toolbar/toolbar.theme";

// BCM
// ToDo: Should be placed in bcm.theme.scss ?
@import "src/app/modules/bcm/products/products.theme";
@import "src/app/modules/bcm/product-categories/product-categories.theme";
@import "src/app/modules/bcm/accounting/invoices/invoices.theme";
@import "src/app/modules/bcm/tenants/tenants.theme";
@import "src/app/modules/bcm/dashboard/dashboards/dashboards.theme";

// Define a mixin for easier access
@mixin components-theme($theme) {

    // Layout components
    @include chat-panel-theme($theme);
    @include toolbar-theme($theme);
    @include products-theme($theme);
    @include product-categories-theme($theme);
    @include invoices-theme($theme);
    @include tenants-theme($theme);
    @include dashboards-project-theme($theme);
}

// -----------------------------------------------------------------------------------------------------
// @ Define the default theme
// -----------------------------------------------------------------------------------------------------

// Define the primary, accent and warn palettes
$default-primary-palette: mat.define-palette($u2b-main);
$default-accent-palette: mat.define-palette($u2b-accent-yellow);
//$default-accent-palette: mat.define-palette($u2b-accent-blue);
$default-warn-palette: mat.define-palette($u2b-red);

// Create the Material theme object
$theme: mat.define-light-theme($default-primary-palette, $default-accent-palette, $default-warn-palette);

// Add ".theme-default" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-default {

    // Create an Angular Material theme company the $theme map
    @include mat.all-legacy-component-themes($theme);

    // Apply the theme to the Fuse Core
    @include fuse-core-theme($theme);

    // Apply the theme to the person components
    @include components-theme($theme);

    // Generate Fuse color classes for primary, accent and warn colors
    $palettes: (
        primary: $default-primary-palette,
        accent: $default-accent-palette,
        warn: $default-warn-palette
    );

    @include fuse-color-classes($palettes);
}

$background: map-get($theme, background);
$foreground: map-get($theme, foreground);
$is-dark: map-get($theme, is-dark);

// Create your Sass color vars (will be available in all the project)
$primary: mat.get-color-from-palette($u2b-main, 500);
$accent: mat.get-color-from-palette($u2b-accent-yellow, 500);
$warn: mat.get-color-from-palette($u2b-red, 500);
$success: mat.get-color-from-palette($u2b-success, 500);

//@debug $primary;

body {
    --color-primary: #{$primary};
    --color-accent: #{$accent};
    --color-warn: #{$warn};
    --color-success: #{$success};
}

.header {

    .search-wrapper {
        background: map-get($background, card);

        .search {

            .mat-icon {
                color: map-get($foreground, icon);
            }

            input {
                background: map-get($background, card);
                color: map-get($foreground, text);
            }
        }
    }
}

@import "src/app/modules/admin/admin.theme";
