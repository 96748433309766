.of-hidden {
    overflow: hidden;
}

fuse-sidebar.w500 {
    width: 500px;
    min-width: 500px;
    max-width: 90vw;
}

//.mat-card-title {
//    font-size: 16px !important;
//    font-weight: 500 !important;
//}
