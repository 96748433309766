.button-group {
    white-space: nowrap;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-right: none;
    border-radius: 4px;
    overflow: hidden; // may cause problems with tooltips. Set a different parent container for tooltip to work.
    display: inline-block; // set to display block from parent component if needed. Or set with to 100% or any other value.

    button,
    bcm-save-button {
        border-radius: 0;
        margin: 0 !important;
        border: none;
        border-right: 1px solid rgba(0, 0, 0, 0.12);
    }

    &.vertical {
        white-space: normal;
        border: 1px solid rgba(0, 0, 0, 0.12);
        border-bottom: none;
        border-radius: 4px;
        overflow: hidden; // may cause problems with tooltips. Set a different parent container for tooltip to work.
        display: block;

        button,
        bcm-save-button {
            width: 100%;
            border-radius: 0;
            margin: 0 !important;
            border: none;
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        }
    }
}

// todo: how to change it the material way?
.mat-fab.mat-accent.mat-button-disabled,
.mat-fab.mat-button-disabled.mat-button-disabled,
.mat-fab.mat-primary.mat-button-disabled,
.mat-fab.mat-warn.mat-button-disabled,
.mat-flat-button.mat-accent.mat-button-disabled,
.mat-flat-button.mat-button-disabled.mat-button-disabled,
.mat-flat-button.mat-primary.mat-button-disabled,
.mat-flat-button.mat-warn.mat-button-disabled,
.mat-mini-fab.mat-accent.mat-button-disabled,
.mat-mini-fab.mat-button-disabled.mat-button-disabled,
.mat-mini-fab.mat-primary.mat-button-disabled,
.mat-mini-fab.mat-warn.mat-button-disabled,
.mat-raised-button.mat-accent.mat-button-disabled,
.mat-raised-button.mat-button-disabled.mat-button-disabled,
.mat-raised-button.mat-primary.mat-button-disabled,
.mat-raised-button.mat-warn.mat-button-disabled {
    background-color: #bbb !important;
    cursor: not-allowed !important;
}

.button-row {
    display: table-cell;
}

.example-button-row .mat-button {
    margin: 8px 8px 8px 0;
}
