@use '@angular/material' as mat;
@import "@angular/material/theming";

@include mat.core();

$u2b-admin-warn: (
        50 : #fae5e6,
        100 : #f3bec1,
        200 : #eb9398,
        300 : #e3676f,
        400 : #dd4750,
        500 : #d72631,
        600 : #d3222c,
        700 : #cd1c25,
        800 : #c7171f,
        900 : #be0d13,
        A100 : #ffebeb,
        A200 : #ffb8b9,
        A400 : #ff8587,
        A700 : #ff6b6e,
        contrast: (
                50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #000000,
                400 : #ffffff,
                500 : #ffffff,
                600 : #ffffff,
                700 : #ffffff,
                800 : #ffffff,
                900 : #ffffff,
                A100 : #000000,
                A200 : #000000,
                A400 : #000000,
                A700 : #000000,
        )
);

$u2b-admin-success: (
        50 : #ffffff,
        100 : #e4f9e7,
        200 : #b6eec0,
        300 : #7ce18d,
        400 : #63dc78,
        500 : #4ad662,
        600 : #31d04c,
        700 : #2ab942,
        800 : #24a039,
        900 : #1f8730,
        A100 : #ffffff,
        A200 : #bcfdc7,
        A400 : #67ec7e,
        A700 : #5ae071,
        contrast: (
                50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #000000,
                400 : #000000,
                500 : #000000,
                600 : #000000,
                700 : #000000,
                800 : #ffffff,
                900 : #ffffff,
                A100 : #000000,
                A200 : #000000,
                A400 : #000000,
                A700 : #000000,
        )
);

$u2b-admin-main: (
        50 : #e2e2e7,
        100 : #b6b8c3,
        200 : #86889b,
        300 : #565873,
        400 : #313555,
        500 : #0d1137,
        600 : #0b0f31,
        700 : #090c2a,
        800 : #070a23,
        900 : #030516,
        A100 : #5668ff,
        A200 : #233aff,
        A400 : #001aef,
        A700 : #0017d5,
        contrast: (
                50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #ffffff,
                400 : #ffffff,
                500 : #ffffff,
                600 : #ffffff,
                700 : #ffffff,
                800 : #ffffff,
                900 : #ffffff,
                A100 : #ffffff,
                A200 : #ffffff,
                A400 : #ffffff,
                A700 : #ffffff,
        )
);

$u2b-admin-accent: (
        50 : #e7ede7,
        100 : #c4d3c2,
        200 : #9db59a,
        300 : #759772,
        400 : #588153,
        500 : #3a6b35,
        600 : #346330,
        700 : #2c5828,
        800 : #254e22,
        900 : #183c16,
        A100 : #83ff7b,
        A200 : #53ff48,
        A400 : #23ff15,
        A700 : #0ffb00,
        contrast: (
                50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #000000,
                400 : #ffffff,
                500 : #ffffff,
                600 : #ffffff,
                700 : #ffffff,
                800 : #ffffff,
                900 : #ffffff,
                A100 : #000000,
                A200 : #000000,
                A400 : #000000,
                A700 : #000000,
        )
);

$u2b-admin-accent: (
        50 : #f1ede1,
        100 : #dcd2b4,
        200 : #c5b582,
        300 : #ae9750,
        400 : #9c802b,
        500 : #8b6a05,
        600 : #836204,
        700 : #785704,
        800 : #6e4d03,
        900 : #5b3c01,
        A100 : #ffd08c,
        A200 : #ffba59,
        A400 : #ffa526,
        A700 : #ff9b0d,
        contrast: (
                50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #000000,
                400 : #ffffff,
                500 : #ffffff,
                600 : #ffffff,
                700 : #ffffff,
                800 : #ffffff,
                900 : #ffffff,
                A100 : #000000,
                A200 : #000000,
                A400 : #000000,
                A700 : #000000,
        )
);

// -----------------------------------------------------------------------------------------------------
// @ Define a admin theme
// -----------------------------------------------------------------------------------------------------

// Define the primary, accent and warn palettes
$admin-primary-palette: mat.define-palette($u2b-admin-main);
$admin-accent-palette: mat.define-palette($u2b-admin-accent); // $u2b-admin-accent1 || $u2b-admin-accent2 || $u2b-admin-accent3 || $u2b-admin-accent4
$admin-warn-palette: mat.define-palette($u2b-admin-warn);

// Create the Material theme object
$u2b-admin-light-theme: mat.define-light-theme($admin-primary-palette, $admin-accent-palette, $admin-warn-palette);

// Add ".theme-admin" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-admin {

    // Generate the Angular Material theme
    @include mat.all-legacy-component-themes($u2b-admin-light-theme);

    // Apply the theme to the Fuse Core
    @include fuse-core-theme($u2b-admin-light-theme);

    // Apply the theme to the person components
    @include components-theme($u2b-admin-light-theme);

    // Generate Fuse color classes for primary, accent and warn colors
    $palettes: (
            primary: $admin-primary-palette,
            accent: $admin-accent-palette,
            warn: $admin-warn-palette
    );

    @include fuse-color-classes($palettes);

    &.has-environment-banner {
        padding-top: 24px;
    }
}
