//.nav-link.bcm-premium {
//    opacity: 0.4;
//    position: relative;
//    //bottom: 0;
//    ////right: 0;
//    //left: 0;
//    //top: auto;
//    background: #474f61;
//    padding: 3px 5px;
//    margin: -3px 0 -3px 10px;
//    color: #FFF;
//    //font-size: 10px;
//    //line-height: 10px;
//
//    //&::before {
//    //    content: '';
//    //    position: absolute;
//    //    bottom: 0;
//    //    left: 100%;
//    //    top: 0;
//    //    height: 0;
//    //    width: 0;
//    //    border: solid #474f61;
//    //    border-width: 8px 5px;
//    //    border-top-color: transparent;
//    //    border-right-color: transparent;
//    //}
//
//    &::after {
//        content: 'Premium';
//    }
//}

$colorWhite: #fff;
$colorSilver: #c0c0c0;
$colorGold: rgba(250, 227, 133, 1);
$colorDarkBlue: #13151b;

.mat-tab-link {
    &.bcm-premium {
        &::after {
            content: "PREMIUM";
            text-align: center;
            padding: 2px 4px 2px 4px;
            font-size: 10px;
            font-weight: 100;
            line-height: 10px;
            letter-spacing: 1px;
            border-radius: 3px;
            background: $colorGold;
            color: $colorDarkBlue !important;
            margin-left: 1rem;
        }

        &.dark::after {
            background: $colorDarkBlue;
            color: $colorGold !important;
        }
    }

    &.bcm-basic {
        &::after {
            content: "BASIC";
            text-align: center;
            padding: 2px 4px 2px 4px;
            font-size: 10px;
            font-weight: 100;
            line-height: 10px;
            letter-spacing: 1px;
            border-radius: 3px;
            background: $colorSilver;
            color: $colorDarkBlue !important;
            margin-left: 1rem;
        }

        &.dark::after {
            background: $colorDarkBlue;
            color: $colorSilver !important;
        }
    }

    &.bcm-add-on {
        &::after {
            content: "ADD-ON";
            text-align: center;
            padding: 2px 4px 2px 4px;
            font-size: 10px;
            font-weight: 100;
            line-height: 10px;
            letter-spacing: 1px;
            border-radius: 3px;
            background: $colorWhite;
            color: $colorDarkBlue !important;
            margin-left: 1rem;
        }

        &.dark::after {
            background: $colorDarkBlue;
            color: $colorWhite !important;
        }
    }
}