@import "src/@fuse/scss/fuse";

@include media-breakpoint('xs') {
    .mat-dialog-actions {
        flex-direction: column;

        .button-group {
            width: 100%;
        }

        button {
            display: block;
            width: 100%;
            margin: 0 0 10px 0 !important;
        }
    }
}
