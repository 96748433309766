.mat-tab-nav-bar {
    background: #fff;

    a {
        &:hover, &:active {
            text-decoration: none !important;
        }
    }
}

.nav-link {
    border: 1px solid transparent;
    border-left-width: 0;

    //&.premium-nav-link {
    //
    //    &::after {
    //        content: "PREMIUM";
    //        position: absolute;
    //        top: auto;
    //        right: 0;
    //        left: auto;
    //        border-bottom-left-radius: 4px;
    //        color: rgba(250, 227, 133, 1) !important;
    //        text-align: center;
    //        padding: 2px 12px 2px 4px;
    //        font-size: 9px;
    //        line-height: 9px;
    //        letter-spacing: 1px;
    //    }
    //
    //    &.accent {
    //        background-color: transparent !important;
    //        border: 1px solid rgba(250, 227, 133, 1);
    //        border-left-width: 0;
    //        color: rgba(250, 227, 133, 1) !important;
    //
    //        &::after {
    //            //top: 0;
    //            //border-bottom-left-radius: 4px;
    //            //background: rgba(250, 227, 133, 1);
    //            //color: #13151b !important;
    //        }
    //    }
    //}
}
