@use '@angular/material' as mat;

@include mat.core();
@include mat.legacy-core();

// Import Fuse core library
@import "@fuse/scss/core";

// Import Leaflet stuff
@import "~leaflet-draw/dist/leaflet.draw.css";
@import "~leaflet-extra-markers/dist/css/leaflet.extra-markers.min.css";

// Import app.theme.scss
@import "app/app.theme";
@import "bcm";

@include mat.all-component-themes($theme);
@include mat.all-legacy-component-themes($theme);

// global styles for tables - discuss correct place to store
// Feature/2596 - responsive Layout - Start
// not being applied in _table.scss styles
body.theme-default:not(#a):not(#b) {

    ::ng-deep .ng-scroll-content {
        padding: 8px;

        @include media-breakpoint('xs') {
            padding: 24px;
        }

        .ng-scroll-content {
            padding: 0;
        }
    }

    .display-none {
        display: none !important;
    }

    .mat-tab-label-container {
        background: #fff;
    }

    .search-wrapper {
        max-width: 480px;
        border-radius: 28px;
        overflow: hidden;
        @include mat.elevation(1);

        @include media-breakpoint('sm') {
            width: 45%;
            position: relative;
        }

        @include media-breakpoint('md') {
            width: 35%;
        }

        @include media-breakpoint('lg') {
            width: 35%;
        }

        .search-input-wrapper {
            height: 36px;
            line-height: 36px;

            @include media-breakpoint('xs') {
                min-width: 60px;
                max-width: 60px;
            }

            @include media-breakpoint('gt-xs') {
                position: relative;
            }

            > input {
                position: absolute;
                left: 16px;
                background-color: white;
                outline: none;
                border: none;
                width: 0;
                z-index: 10;
                transition-duration: 0.4s;
                -moz-transition-duration: 0.4s;
                -webkit-transition-duration: 0.4s;
                -o-transition-duration: 0.4s;
                padding: 0;
                height: 36px;
                border-radius: 4px;

                @include media-breakpoint('gt-xs') {
                    width: 170px;
                    left: 50px;
                    top: 0;
                }

                &:focus {
                    width: calc(100% - 32px);
                    padding: 0 16px;
                    height: 36px;
                    border-radius: 4px;
                    top: 44px;

                    @include media-breakpoint('gt-xs') {
                        width: 170px;
                        padding: 0;
                        top: 0;
                    }
                }
            }

            label {
                padding: 0 18px;
                height: inherit;
                display: block;
            }

            .mat-icon {
                height: inherit;
                line-height: inherit;
                color: #0000008a;
            }
        }
    }

    .top-bg {
        // these heights are set so that the BG ends on the border of the mat-table-header
        height: 130px;

        @include media-breakpoint('sm') {
            height: 167px !important;
        }

        @include media-breakpoint('md') {
            height: 160px !important;
        }

        @include media-breakpoint('gt-md') {
            height: 176px !important;
        }
    }

    // move this to u2b confirm Dialog
    .mat-toolbar-row {
        padding: 0 10px;

        .dialog-title {
            @include media-breakpoint('xs') {
                font-size: 14px;
            }
        }
    }

    .mat-table {
        position: relative;
        width: 100%;
        flex-direction: column;
        //overflow-x: auto;
    }

    mat-row, mat-header-row {
        min-width: auto;
        // TODO: MALTE FIX
    }

    .mat-button {
        @include media-breakpoint('md') {
            padding: 0 15px !important;
        }
    }
}

::ng-deep {
    .mat-dialog-container {
        .mat-toolbar-row {
            background-color: rebeccapurple;

            .dialog-title {
                @include media-breakpoint('sm') {
                    font-size: 24px;
                }
            }
        }
    }
}

// global styles for tables - discuss correct place to store
// Feature/2596 - responsive Layout - END

.light-backdrop {
    background: rgba(0, 0, 0, 0.2);
}

.mat-paginator {
    .mat-form-field-infix {
        border-top: 0 !important;
    }
}


.toast-info {
    background-color: #13151b !important;
    //border: 1px solid darken(#13151b, 5%);
    //border-left-width: 6px;
    box-shadow: 0 0 12px darken(#13151b, 5%) !important;

    .toast-progress {
        background: #fff;
    }
}

/*.mat-header-row, */
.mat-row {
    // why did I set this here?
    //min-height: 0 !important;

    p {
        margin: 5px 0 !important;
    }
}

// override the color of the selection rectangle
//$select-box-color: red;
@import 'ngx-drag-to-select/scss/ngx-drag-to-select';

// ToDo: create components for different snackBar types ?
// https://stackblitz.com/edit/angular-m2nc51?file=app/snack-bar-component-example.ts
body.theme-default,
body.theme-admin {

    --default-space: 8px;

    @include media-breakpoint('sm') {
        --default-space: 12px;
    }

    @include media-breakpoint('md') {
        --default-space: 16px;
    }

    @include media-breakpoint('gt-md') {
        --default-space: 24px;
    }

    @import "icons";
    @import "button-group";
    @import "buttons";
    @import "nav-links";
    @import "form";
    @import "sidebar";
    @import "map-stuff";
    @import "flags";
    @import "table";
    @import "dialogs";
    @import "menu";

    .subtitle.secondary-text.highlight {
        color: #2b2c7c;
        opacity: 0.8;
        //color: #2e8fb2;
    }

    .flex-select-option {
        .mat-option-text {
            width: 100%;
            display: flex !important;
            justify-content: space-between !important;
            align-items: center;
        }

        .mat-icon {
            margin: 0 !important;
        }
    }

    *:not(.mat-form-field-label-wrapper) {
        box-sizing: border-box;
    }

    [fxlayoutalign="flex-end center"] .mat-sort-header-content {
        text-align: right;
        align-items: end;
    }

    .no-bg {
        background: none !important;
    }

    .mat-form-field-prefix,
    .mat-form-field-suffix {
        display: inline-flex;
        align-items: center;
    }

    dashboard u2b-page-header,
    .top-bg {
        background: url('assets/images/backgrounds/header-bg.png') center center / cover no-repeat !important;
        object-fit: contain;
        background-size: cover;
    }

    .top-bg {
        + .center {
            .logo,
            .logo-text,
            .subtitle {
                color: #fff !important;
            }
        }
    }

    .float-right {
        float: right;
    }

    .float-left {
        float: left;
    }

    .sum {
        border-top: 1px solid rgba(0, 0, 0, 0.12);
        border-bottom: 3px double rgba(0, 0, 0, 0.12);
    }

    .special-divider {
        position: relative;
        margin: 25px 10%;
        width: calc(100% - 20%);

        &::after {
            position: absolute;
            content: "";
            width: 30px;
            height: 30px;
            left: 50%;
            top: -15px;
            margin-left: -15px;
            background: #ffff url("/assets/icons/ship_wheel_grey.svg") center center no-repeat;
        }

        //&::before {
        //    position: absolute;
        //    content: "";
        //    width: 15px;
        //    height: 15px;
        //    left: 50%;
        //    top: -8px;
        //    margin-left: -8px;
        //    background: #d6d6d6;
        //    border-radius: 15px 0;
        //    transform: rotate(45deg);
        //    background-image: url("/assets/icons/ship_wheel.svg");
        //}
        //
        //&::after {
        //    position: absolute;
        //    content: "";
        //    width: 13px;
        //    height: 13px;
        //    left: 50%;
        //    top: -7px;
        //    margin-left: -7px;
        //    background: #fff;
        //    border-radius: 13px 0;
        //    transform: rotate(45deg);
        //}
    }

    .default-dialog {

        @include media-breakpoint('xs') {
            max-width: none !important;
            width: 100% !important;
            height: 100% !important;
        }

        @include media-breakpoint('gt-xs') {
            width: 640px;
        }

        &.wider-dialog {
            @include media-breakpoint('gt-xs') {
                width: 980px;
            }
        }

        &.has-min-height-250 .mat-dialog-content {
            min-height: 250px;
        }

        &.has-min-height-500 .mat-dialog-content {
            min-height: 500px;
        }

        .mat-dialog-container {
            padding: 0;

            @include media-breakpoint('xs') {
                border-radius: 0;
            }
        }

        .mat-dialog-content {
            max-height: none;
            margin: 0 !important;
            padding: 16px !important;

            @include media-breakpoint('gt-xs') {
                padding: 24px !important;
                max-height: 65vh;

                &.p-0 {
                    padding: 0 !important;
                }
            }

            &.p-0 {
                padding: 0 !important;
            }

            .mat-vertical-stepper-header {
                padding: 24px 4px !important;
            }

            .mat-vertical-content-container {
                margin-left: 16px !important;
            }
        }

        .dialog-content-wrapper {
            max-height: 85vh;
            display: flex;
            flex-direction: column;

            @include media-breakpoint('xs') {
                max-height: none;
            }
        }

        &.xxl-dialog {
            max-width: 90% !important;
            width: 1100px !important;

            .dialog-content-wrapper {
                max-height: 90vh;
                min-height: 90vh;
                display: flex;
            }

            .mat-dialog-content {
                max-height: 100% !important;
                flex: 1;
            }

            .mat-dialog-actions {
                border-top: 1px solid rgba(0, 0, 0, 0.3);
                padding: 0 16px;
                margin: 0;
                min-height: 80px;
            }

        }

    }

    .invoice-form-dialog {
        max-width: 90% !important;
        width: 1300px !important;
        height: 95vh;

        mat-dialog-container {
            padding: 0;
        }

        mat-toolbar {
            position: sticky;
            top: 0;
            background: #ff9d00;
            color: white;
            z-index: 999;
        }

    }

    .d-block {
        display: block !important;
    }

    &.has-environment-banner {
        padding-top: 24px;

        .cdk-overlay-container {
            .mat-dialog-container {
                margin-top: 24px;
                height: calc(100% - 24px) !important;
            }
        }
    }

    // ALWAYS show the scroll bars of perfect scrollbar
    .ps > .ps__rail-x,
    .ps > .ps__rail-y {
        opacity: 0.6;
    }

    .radio-group {
        display: flex;
        flex-direction: column;
        margin: 15px 0;

        .mat-radio-button {
            margin: 5px;
        }
    }

    .radio-group-inline {
        &.ng-invalid {
            color: $warn;
        }

        .mat-radio-button {
            margin-right: 16px;
        }
    }

    .navbar-content {
        padding: 0 !important;
    }

    .nav {
        .nav-group {
            > .group-title {
                white-space: normal;
                padding-right: 24px;
                margin-top: 24px;
                margin-bottom: 8px;
                height: auto;
            }
        }

        .nav-link.release-info {
            font-size: 11px !important;
            font-weight: normal !important;
            color: #ff9d00 !important;
            height: 15px !important;
            margin-top: 24px;
            margin-bottom: 10px;
        }
    }

    .mat-form-field-wrapper {
        padding-bottom: 0 !important;
    }

    .mat-form-field-appearance-outline .mat-form-field-wrapper {
        margin: .25em 0 .5em;
    }

    .mat-checkbox-layout {
        white-space: normal !important;
    }

    .mat-snack-bar-container {
        background: #fff;
        color: #1E2129;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        max-width: 95vw;

        &.success {
            border-color: #4CAF50;
        }

        &.failure {
            border-color: #F44336;

            .mat-simple-snackbar-action {
                color: #F44336;
            }
        }

        &.warning {
            border-color: #FFC107;
        }

        &.info {
            border-color: #B3E5FC;
        }
    }

    .mat-card-header .mat-card-header-text:first-child {
        margin: 0 !important;
    }

    [ng-reflect-router-link] {
        cursor: pointer;
    }

    .button-list {
        &.button-list-vertical {
            button {
                width: 100% !important;
                min-width: unset !important;

                &:not(:last-of-type) {
                    margin-bottom: 16px;
                }
            }
        }
    }

    .mat-spinner.saving-spinner {
        position: absolute !important;
        top: 50%;
        left: 50%;
        margin: -10px 0 0 -10px;
    }

    // Workaround for https://github.com/angular/material2/issues/4580.
    mat-form-field .mat-form-field {
        &-underline {
            position: static;
        }

        &-subscript-wrapper {
            position: static;
            line-height: 1.2em;
        }
    }

    &:not(.sidebar-table):not(.not-striped) mat-row.mat-row:nth-child(even),
    mat-row.mat-row.with-background {
        background: rgba(0, 0, 0, 0.05);
    }

    table {
        width: 100%;
        border: none;

        &.v-align-t td {
            vertical-align: top;
        }

        &:not(.sidebar-table):not(.not-striped) tbody tr:nth-child(even),
        tbody tr.with-background {
            background: rgba(0, 0, 0, 0.05);
        }

        &.sidebar-table {
            th {
                text-align: left;
            }

            tr:not(:last-of-type):nth-child(even) {
                th, td {
                    border-bottom: 1px solid rgba(0, 0, 0, .12);
                }
            }
        }
    }

    .float-left {
        float: left !important;
    }

    .float-right {
        float: right !important;
    }

    .mat-option {
        white-space: unset;
        overflow: unset;
        text-overflow: unset;
        display: block;
        line-height: 1.2;
        height: auto;
        padding: 12px 16px;
    }

    .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
    .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
        color: rgba(0, 0, 0, 0.87);
        font-weight: 600;
    }

    .mat-row {
        height: auto;
    }

    .mat-table {
        .mat-row {
            position: relative;
            cursor: pointer;
        }

        &.no-pointer {
            .mat-row {
                cursor: default;
            }
        }
    }

    .mat-cell,
    .mat-footer-cell,
    .mat-header-cell {
        padding: 0 16px;

        &:first-of-type {
            padding-left: 24px;
        }

        &:last-of-type {
            padding-right: 24px;
        }
    }

    .mat-table {
        flex: 1 1 auto;
        //border-bottom: 1px solid rgba(0, 0, 0, .12);
        //overflow: auto;
        //-webkit-overflow-scrolling: touch;

        .mat-column-id,
        .mat-column-pos {
            -webkit-box-flex: 0;
            flex: 0 1 74px;
            width: 74px;
        }

        .mat-column-checkbox {
            flex: 0 1 64px;
            width: 64px;
            padding-left: 18px;
        }

        .mat-column-avatar {
            flex: 0 1 64px;
        }

        .mat-row {
            position: relative;
            cursor: pointer;

            .mat-cell {
                min-width: 0;
            }
        }

        &.no-pointer {
            .mat-row {
                cursor: default;
            }
        }
    }

    .text-left {
        text-align: left !important;
    }

    .text-center {
        text-align: center !important;
    }

    .text-right {
        text-align: right !important;
    }

    .mat-tooltip {
        font-size: 1.3rem !important;
        background: #fff;
        color: inherit;
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.35);
    }

    .mat-toolbar.cdk-drag-handle,
    .mat-dialog-title.cdk-drag-handle {
        &:hover {
            cursor: move;
        }
    }
}

.shrink-quarter {
    transform: scale(.75);
}

.shrink-2x {
    transform: scale(.5);
}

.accent-box-highlight {
    border-left: 3px solid #ff9d00 !important;
}

.mat-simple-snackbar > span {
    overflow: hidden;
}

.cursor-grab {
    cursor: grab !important;
}

.cursor-crosshair {
    cursor: crosshair !important;
}

.pier-angle-label {
    width: 30px;
    height: 30px;
    border-radius: 20px !important;
    text-align: center;
}

.small-icon-button {
    width: 24px !important;
    height: 24px !important;
    padding: 0 !important;
    display: inline-flex !important;
    align-items: center;
    justify-content: center;

    & > *[role=img] {
        width: 16px;
        height: 16px;
        font-size: 16px;

        svg {
            width: 16px;
            height: 16px;
        }
    }

    .mat-icon {
        width: 24px !important;
        height: 24px !important;
        line-height: 24px !important;
    }

    .material-icons {
        font-size: 16px !important;
    }
}

.color-warn {
    color: #b50a00 !important;
}

.overflow-wrap-anywhere {
    overflow-wrap: anywhere;
}

.smaller-u2b-message {
    padding: 8px 16px !important;

    span {
        font-size: smaller;
    }
}

.widget-settings {
    padding: var(--default-space);
}

u2b-membership-program-members-list {
    width: 100%;
}

u2b-membership-program-member-form {
    width: 100%;
}

.header-align-right {
    text-align: right;
    justify-content: flex-end;
}

.cell-align-right {
    text-align: right;
    justify-content: flex-end;
}

.header-align-center {
    text-align: center;
    justify-content: center;
}

.cell-align-center {
    text-align: center;
    justify-content: center;
}

.mat-date-range-input-wrapper {
    max-width: unset !important
}

.voucher-option {
    .mat-option-text {
        width: 100%;
    }
}

// fast try of using svg pattern via css class...
.diagonal-hatching {
    //mask: url("data:image/svg+xml,\
    //<svg xmlns='http://www.w3.org/2000/svg' height='100%' width='100%'>\
    //    <defs>\
    //        <pattern id='diagonalHatching' width='5' height='5' patternTransform='rotate(45 0 0)' patternUnits='userSpaceOnUse'>\
    //            <line x1='4' x2='4' y1='0' y2='10' style='stroke:white; stroke-width:2'/>\
    //        </pattern>\
    //    </defs>\
    //    <rect width='100%' height='100%' fill='url(%23diagonalHatching)'/>\
    //</svg>");
}

.form-field-date-picker {
    .mat-form-field-infix {
        display: flex;
    }
}

.time-picker {
    tr {
        background: unset !important;
    }
}

.mat-option-berth-assignment {
    .mat-option-text {
        display: flex !important;
        flex-direction: column !important;
        width: 100%;
    }
}

booking-dialog {
    .mat-tab-labels {
        display: flex;
        justify-content: space-between;
    }

    .mat-tab-header {
        position: sticky;
        top: -26px;
        left: 0;
        z-index: 99;
    }

    .mat-tab-label {
        min-width: 25px !important;
        padding: 16px;
    }
}

.with-loading-overlay {
    position: relative;

    .loading-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(255, 255, 255, 0.75);
        z-index: 10;
    }
}
