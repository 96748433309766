.mat-sort-header-disabled .mat-sort-header-arrow {
    display: none;
}

table.simple.sm {
    thead, tbody, tfoot {
        tr {
            &:nth-child(even) .mat-table-sticky-border-elem-right {
                background: #f2f2f2;
            }

            th, td {
                padding: 4px !important;
                min-width: 25px;

                &:not(:last-of-type) {
                    border-right: 1px solid rgba(0, 0, 0, 0.12);
                }

                &.first {
                    width: 90px;
                }

                &.no-border {
                    border: none;
                }

                &.sum {
                    border-top: none;
                    border-bottom: 3px double rgba(0, 0, 0, 0.12);
                }
            }
        }
    }
}

.drag-indicator {
    cursor: grab;
}


// global styles for tables - discuss correct place to store
// Feature/2596 - responsive Layout - Start

table.mat-table {

    &.layout-fixed {
        table-layout: fixed;
    }

    .mat-cell:first-of-type,
    .mat-header-cell:first-of-type {
        @include media-breakpoint('sm') {
            padding-left: 16px !important;
        }

        @include media-breakpoint('md') {
            padding-left: 42px !important;
        }
    }

    .mat-row {
        &:nth-child(even) .mat-table-sticky-border-elem-right {
            background: #f2f2f2;
        }
    }

    mat-cell {
        align-items: center !important;
    }

    .mat-column-checkbox {
        width: 64px;
    }

    .mat-column-avatar {
        width: 64px;
    }

    .mat-column-buttons,
    .mat-column-actions {
        width: 80px;
    }
}

mat-table.mat-table {
    mat-header-row {
        min-height: 40px;

        @include media-breakpoint('sm') {
            min-height: 48px !important;
        }

        @include media-breakpoint('gt-md') {
            min-height: 56px !important;
        }
    }

    .mat-row {
        &:nth-child(even) .mat-table-sticky-border-elem-right {
            background: #f2f2f2;
        }
    }

    mat-cell {
        align-items: center !important;
    }

    mat-cell > p {
        margin: 0 !important;
    }

    .mat-cell:first-of-type,
    .mat-header-cell:first-of-type {
        @include media-breakpoint('gt-sm') {
            padding-left: 16px !important;
        }
    }

    .mat-column-checkbox {
        flex: 0 1 64px;
        //padding-left: 18px;
    }

    .mat-column-avatar {
        flex: 0 1 64px;
    }

    .mat-column-buttons,
    .mat-column-actions {
        flex: 0 1 80px;
    }

    @media (orientation: landscape) and (max-width: 900px) {
        //.mat-table:not(#a):not(#b) {
        //    height: 60vh;
        //}

        .mat-table-sticky {
            position: static !important;
        }
    }
}

.mat-paginator-page-size-label {
    display: none;

    @include media-breakpoint('sm') {
        display: block;
    }
}

// global styles for tables - discuss correct place to store
// Feature/2596 - responsive Layout - END
