@mixin dashboards-project-theme($theme) {

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $accent: map-get($theme, accent);
    $primary: map-get($theme, primary);
    $is-dark: map-get($theme, is-dark);

    #dashboard-project {

        .fuse-card {
            a {
                //background-color: map-get($primary, default);
                color: map-get($primary, default);
            }
        }

        .dashboard-content {
            .fuse-card {
                > div:first-of-type {
                    background-color: map-get($accent, default);
                    color: map-get($accent, default-contrast);
                }
            }
        }

        > .center {

            > .content {
                @if ($is-dark) {
                    background: map-get($background, background);
                } @else {
                    background: map-get($background, app-bar);
                }
            }
        }
    }
}