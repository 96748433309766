.mat-cell,
td {
    .mat-input-element,
    .table-row-input,
    .mat-select {
        border: 1px solid lightgrey;
        background: #fff;
        padding: 0.5rem;
    }
}

editor.ng-invalid.ng-touched {
    .tox.tox-tinymce {
        border: 1px solid #ff3b30;
    }
}

.mat-radio-group.ng-invalid.ng-touched .mat-radio-outer-circle {
    border-color: #ff3b30;
}

.mat-radio-group.ng-invalid.ng-touched .mat-radio-label-content {
    color: #ff3b30;
}

.tox-menu {
    min-width: 140px;
    white-space: nowrap;
}

.date-range-picker .mat-calendar-body-today:not(.mat-calendar-body-selected) {
    border-color: darkgreen;
}

.mat-form-field {
    max-width: 100%;
}
