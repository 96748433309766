.u2b-marker-pin {
    width: 30px;
    height: 30px;
    border-radius: 50% 50% 50% 0;
    background: #ff9d00;
    position: absolute;
    transform: rotate(-45deg);
    left: 50%;
    top: 50%;
    margin: -15px 0 0 -15px;
    border: 2px solid rgba(0, 0, 0, 0.2);
    border-left-width: 0;
    border-right-width: 1px;
    border-top-width: 0;
}

// to draw white circle
.u2b-marker-pin::after {
    content: '';
    width: 24px;
    height: 24px;
    margin: 3px 0 0 3px;
    //background: rgba(0, 0, 0, 0.125);
    //background: #fff;
    //box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
    position: absolute;
    border-radius: 50%;
}

// to align icon
.u2b-marker-icon i {
    position: absolute;
    width: 22px;
    font-size: 20px;
    left: 0;
    right: 0;
    margin: 10px auto;
    text-align: center;
    color: #fff;
    text-shadow: 0 1px 1px #000;
}

.map-text {
    width: 0 !important;
    height: 0 !important;
    border: 0 !important;
    padding: 0 !important;
    margin: 0 !important;

    &.pulse {
        .map-text-content > div {
            animation: pulse-white 1.5s ease-in-out infinite;
        }
    }
}

.map-text-content {
    display: inline-block;
    padding: 0 4px;
    border-radius: 3px;
    transform: translate(-50%, -50%);
    text-align: center;
    background: transparent !important;

    > div {
        display: inline-block;
        padding: 0 4px;
        border-radius: 3px;
        text-align: center;
    }

    @keyframes pulse-white {
        0% {
            box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.9);
        }

        70% {
            box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
        }

        100% {
            box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
        }
    }
}

.most-free {
    background: url('/assets/images/svg/white-thumbs-up.svg') center center / cover no-repeat !important;
    width: 100px;
    height: 100px;
}

.map-info-marker-container {
    display: inline-block;
    border-radius: 50%;
    text-align: center;
    background: #fff;
    width: 18px;
    height: 18px;
    box-shadow: 1px 2px 4px rgb(0 0 0 / 60%);

    &:after {
        content: "";
        position: absolute;
        top: 16px;
        left: 4px;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 7px solid #fff;
        z-index: 2;
    }

    .map-info-marker {
        border-radius: 50%;
        background: #fff;
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        margin: 1px;
        z-index: 3;
    }
}

.map-electric-marker-container {
    display: inline-block;
    border-radius: 50%;
    text-align: center;
    background: #ffc400;
    width: 24px;
    height: 24px;
    box-shadow: 1px 2px 4px rgb(0 0 0 / 60%);
    color: #000;

    &.highlight,
    &.connected-elements {
        background: #000;
        color: #ffc400;

        &:after {
            border-top: 10px solid #000;
        }
    }

    &:after {
        content: "";
        position: absolute;
        top: 20px;
        left: 4px;
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 10px solid #ffc400;
        z-index: 2;
    }
}

.berth-buoy-marker {

    path {
        fill: white;
    }

    &.hide {
        display: none;
    }

    &.bg-grey path {
        fill: grey;
    }

    &.bg-yellowgreen path {
        fill: yellowgreen;
    }

    &.bg-darkgreen path {
        fill: darkgreen;
    }

    &.bg-red path {
        fill: red;
    }

    &.bg-orange path {
        fill: orange;
    }

    &.highlight path:not(:nth-child(1)) {
        stroke-width: 2;
    }
}

.u2b-scrollable {
    max-height: 400px;
}

.leaflet-tooltip .color-white {
    color: white;
}
