.has-flag {
    position: relative;
}

.new-flag {
    //opacity: 0.4;
    position: relative;
    background: #474f61;
    padding: 3px 5px;
    margin: -3px 0 -3px 10px;
    color: #FFF;

    &::after {
        content: 'NEU';
    }
}